import React from 'react';
import Rose from '../assets/rose.svg';
import CustomLink from '../hooks/CustomLink';

function RoseIcon ({}) {
    return (
<CustomLink to="/">
<img src={Rose} alt="Compass Rose" className="rose-icon" />    
</CustomLink>  
    );
  };

export default RoseIcon;