import React from 'react';
import './ProtocolChart.css';
import CustomLink from '../hooks/CustomLink';


function ProtocolChart({ protocols }) {
    const maxCoordinate = 10; // Coordinate range from -10 to 10

    return (
        <div className="protocol-chart">


            {protocols.map((protocol) => (
                <CustomLink
                    to={`/${protocol.title.toLowerCase().replace(/ /g, '-')}`}
                    key={protocol.title}
                    className="protocol-point"
                    style={{
                        left: `calc(50% + ${(protocol.x / maxCoordinate) * 50}%)`, // Adjust left from center
                        top: `calc(50% - ${(protocol.y / maxCoordinate) * 50}%)` // Adjust top from center
                    }}
                    title={protocol.title}
                >
                    {protocol.title}
                </CustomLink>
            ))}
            <div className="axis-label x-label-hard">Hard</div>
            <div className="axis-label x-label-soft">Soft</div>
            <div className="axis-label y-label-systemic">Systemic</div>
            <div className="axis-label y-label-atomic">Atomic</div>
        </div>
    );
}

export default ProtocolChart;
