import React, { useState } from 'react';
import './ExpandableSection.css';
import ProtocolChart from './ProtocolChart';
import protocols from '../protocols';

function ExpandableSection() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`expandable-section ${isOpen ? 'open' : ''}`}>
            <button onClick={toggleOpen}>
                {isOpen ? 'Less' : 'More'}
            </button>
    
            {isOpen && (
                <div className="expandable-content">
                    <p>It is useful to think about protocols along two basic dimensions: <b>hard to soft</b> and <b>atomic to systemic</b>.</p>
                    <br />
                    <p>A hard protocol has inflexible expectations. Small deviations cause errors. Traditional computer protocols are usually hard, but ones capable of sustaining more forgiving interactions, using AI elements for example, can be soft. A soft protocol accommodates a wide range of behaviors. Human behavioral protocols, like handshakes, are usually soft, but formal ones, such as military ceremonies, can have nearly machine-like hardness.</p>
                    <br />
                    <p>An atomic protocol is one that is difficult or pointless to further decompose, while a systemic protocol is one that is made up of many atomic protocols, often organized in strata.</p>
                    <br />
                    <ProtocolChart protocols={protocols} />

                </div>
            )}
        </div>
    );
}

export default ExpandableSection;
