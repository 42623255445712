import React from 'react';
import CustomLink from '../hooks/CustomLink';
import './ListItem.css';

function ListItem({ number, title, description }) {
  return (
    <CustomLink to={`/${title.toLowerCase().replace(/ /g, '-')}`} className="list-item-link">
      <div className="list-item">
        <div className="list-item-number">{title}</div>
        <div className="list-item-content">
          {/* <div className="list-item-title">{title}</div> */}
          <p className="list-item-description">{description}</p>
        </div>
      </div>
    </CustomLink>
  );
}

export default ListItem;
