import React from "react";
import './Info.css';

function Info({ }) {
    return (

        <div className="info-description">
            <h2>A protocol is a set of explicit or implicit rules that guide behavior and facilitate coordinated action.
                <br />
                <br />
                This list of protocols has been curated to build your intuition for what a protocol is, how to recognize them, and the implications of their design.
            </h2>
            {/* <br />
            <br />
            <div className="sub-info">
                It is useful to think about protocols along two basic dimensions: <b>hard to soft</b> and <b>atomic to systemic</b>.
                <br />
                <br />
                A hard protocol has inflexible expectations. Small deviations cause errors. Traditional computer protocols are usually hard, but ones capable of sustaining more forgiving interactions, using AI elements for example, can be soft. A soft protocol accommodates a wide range of behaviors. Human behavioral protocols, like handshakes, are usually soft, but formal ones, such as military ceremonies, can have nearly machine-like hardness.
                <br />
                <br />
                An atomic protocol is one that is difficult or pointless to further decompose, while a systemic protocol is one that is made up of many atomic protocols, often organized in strata.
            </div> */}
        </div>
    );

}


export default Info;