import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ListItem from './components/ListItem';
import Title from './components/Title';
import ProtocolDetail from './components/ProtocolDetail';
import './App.css';
import protocols from './protocols';
import Info from './components/Info';
import Footer from './components/Footer';
import ExpandableSection from './components/ExpandableSection';
import RoseIcon from './components/RoseIcon';


function App() {
  return (
    <Router>
      <div className="app">
      <RoseIcon />
        <div className='info'>
          <Title title="Protocol Guide" />

          <Routes>
            <Route path="/" element={
              <div>
                <Info />
                <ExpandableSection />
                {/* <div className='protocols-header'>
                        <h4>Protocols</h4>
                      </div> */}
                {protocols.map(p => (
                  <ListItem key={p.number} number={p.number} title={p.title} description={p.description} />
                ))}
              </div>
            } />
            {protocols.map((protocol, index) => (
              <Route key={protocol.number} path={`/${protocol.title.toLowerCase().replace(/ /g, '-')}`} element={
                <ProtocolDetail protocol={protocol} index={index} total={protocols.length} />
              } />
            ))}
          </Routes>
          <Footer />
        </div>


        {/* <div className='chart'>
        <Routes>
          <Route path="/" element={
            
          } />
        </Routes>
        </div> */}
      </div>
    </Router>
  );
}

export default App;
