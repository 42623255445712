import React from 'react';
import './Footer.css';

function Footer ({}) {
        return (
            <div className='footer'>
                Placeholder License Notice: Content within the "Summer of Protocols" is initially licensed under CC BY-NC 4.0 by the respective authors, transitioning to CC BY 4.0 on December 13, 2026. This footer is a placeholder and may not represent the final licensing terms.
            </div>
        );
}

export default Footer;